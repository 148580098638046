<template>
  <div>
    <el-input
      class="AmountInput"
      :value="value1"
      v-bind="$attrs"
      v-on="currentListeners"
      @blur="blur"
    ></el-input>
    <span class="text">至</span>
    <el-input
      class="AmountInput"
      :value="value2"
      v-bind="$attrs"
      v-on="currentListeners1"
      @blur="blur"
    ></el-input>
  </div>
</template>

<script>
import { EmojiReg } from '@/config/reg-config'
export default {
  props: {
    value1: {},
    value2: {}
  },
  data () {
    return {}
  },
  watch: {},
  // 计算属性 类似于data概念
  computed: {
    currentListeners () {
      return Object.assign({}, this.$listeners, {
        input: this.handleInput
      })
    },
    currentListeners1 () {
      return Object.assign({}, this.$listeners, {
        input: this.handleInput1
      })
    }
  },
  // 方法集合
  methods: {
    // 失去焦点验证小数点后有无数字
    blur () {
      let value = this.value
      if (!value) {
        return
      }
      const arr = value.toString().split('.')
      if (arr.length > 1 && !arr[1]) {
        value = arr[0]
      }
      this.$emit('input', value)
    },
    handleInput1 (e) {
      // 替换掉表情输入
      let value = e.replace(EmojiReg, '')
      // 替换 首位为0 输入不为 . 的字符
      const numberarr = value.split('')
      if (
        numberarr[0] === '0' &&
        numberarr.length > 1 &&
        numberarr[1] !== '.'
      ) {
        return
      }
      // 替换 首位为 . 和多个 .
      if (value.indexOf('.') > -1) {
        const arr = value.split('.')
        if (!arr[0]) {
          return
        }
        if (arr.length > 2) {
          return
        }
      }
      if (this.$attrs.type === 'Y') {
        // 元
        if (value > 9999999999999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'G') {
        if (value > 99999999999999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'WY') {
        // 万元
        if (value > 999999999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'YY') {
        // 亿元
        if (value > 99999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'BL') {
        // 0.01~100 用于比例
        if (value && value > 100) {
          return
        } else if (value && value < 0) {
          return
        }
      }
      // 只能为数字和最多两位小数
      value = value
        .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/^\./g, '') // 验证第一个字符是数字而不是
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$emit('secondInput', value)
    },
    handleInput (e) {
      // 替换掉表情输入
      let value = e.replace(EmojiReg, '')
      // 替换 首位为0 输入不为 . 的字符
      const numberarr = value.split('')
      if (
        numberarr[0] === '0' &&
        numberarr.length > 1 &&
        numberarr[1] !== '.'
      ) {
        return
      }
      // 替换 首位为 . 和多个 .
      if (value.indexOf('.') > -1) {
        const arr = value.split('.')
        if (!arr[0]) {
          return
        }
        if (arr.length > 2) {
          return
        }
      }
      if (this.$attrs.type === 'Y') {
        // 元
        if (value > 9999999999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'G') {
        if (value > 99999999999999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'WY') {
        // 万元
        if (value > 999999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'YY') {
        // 亿元
        if (value > 99999.99 && value) {
          return
        }
      } else if (this.$attrs.type === 'BL') {
        // 0.01~100 用于比例
        if (value && value > 100) {
          return
        } else if (value && value < 0) {
          return
        }
      }
      // 只能为数字和最多两位小数
      value = value
        .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/^\./g, '') // 验证第一个字符是数字而不是
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
        .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')

      this.$emit('firstInput', value)
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    console.log(this.$attrs, 777)
  }
}
</script>
<style lang='scss' scoped>
.text {
  padding: 0 5px;
}

.AmountInput {
  width: 45% !important;
}
</style>
